import React, { useCallback } from "react";
import "./Footer.css";
import NewsletterForm from "./NewsletterForm";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  // const contactLink = useCallback(() => {
  //   navigate("/contact");
  // }, [navigate]);

  return (
    <div className="footer">
      <div className="footer1">
        <div className="footer-grid-row">
          <div className="footer-menu">
            <img className="footerlogo-icon" alt="" src="/footerlogo.svg" />
            <div className="footer-links">
              <div className="footerlinknav">
                <span className="nav-link">info@scholistapp.com</span>
              </div>
              {/*  <div className="footerlinknav">
                <span className="nav-link">+1 234 567 9830</span>
              </div>
              <div className="footerlinknav">
                <span className="nav-link">Address</span>
              </div> */}
            </div>
            <div className="icon-wrapper">
              <a
                href="https://instagram.com/scholistapp?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericoninstagram"
                  alt="Instagram"
                  src="/footericoninstagram.svg"
                />
              </a>
              <a
                href="https://www.facebook.com/Scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonfacbook"
                  alt="Facebook"
                  src="/footericonfacbook.svg"
                />
              </a>
              <a
                href="https://twitter.com/scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericontwitter"
                  alt="Twitter"
                  src="/footericontwitter.svg"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/scholist/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonlinkedin"
                  alt="LinkedIn"
                  src="/footericonlinkedin.svg"
                />
              </a>
              <a
                href="https://www.tiktok.com/@scholistapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericontwitter"
                  alt="Twitter"
                  src="/footericontiktok.svg"
                />
              </a>
              <a
                href="https://www.youtube.com/@scholistapp4239"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footericonyoutube"
                  alt="YouTube"
                  src="/footericonyoutube.svg"
                />
              </a>
            </div>
          </div>
          <div className="footer-menu-parent">
            <div className="footer-menu1">
              <span className="students">Students</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">Scholarships</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Community</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Services</span>
                </div>
                <div className="main-footer-link">
                  <span
                    className="footer-link"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Resources 
                  </span>
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Legal</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">Privacy policy</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Blog</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Terms of Service</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link" style={{whiteSpace: "nowrap", cursor: "default"}}> Do Not Sell  My Personal Info</span>
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Company</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">About Us</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Partners</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Careers</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">
                    <a
                      href="mailto: info@scholistapp.com"
                      className="footer-link"
                      style={{ color: "white" }}
                    >
                      Contact
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <NewsletterForm />
          </div>
        </div>
        <div className="footer-legal">
          <span className="scholist-all-rights">
            © 2022 Scholist. All rights reserved.
          </span>
          <div className="disclaimer-cookies">Disclaimer Cookies</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
