import axios from "axios";
import "./ProfileForm.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCloudUploadAlt } from "react-icons/fa";

const ProfileForm = () => {
  const navigate = useNavigate();
  const [coach, setCoach] = useState({
    hourlyRate: "",
    bio: "",
    expertise: "",
    location: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [showeModal, setShoweModal] = useState(false);
  const [file, setFile] = useState(null);

  // States for form sections
  const [services, setServices] = useState([]);
  const [service, setService] = useState({
    name: "",
    details: "",
    duration: "",
    link: "",
    price: "",
  });

  const [educations, setEducations] = useState([]);
  const [education, setEducation] = useState({
    abbreviation: "",
    course: "",
    universityName: "",
  });

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState("");

  const [policies, setPolicies] = useState([]);
  const [policie, setPolicie] = useState("");

  // Unified change handler for coach fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoach((prevCoach) => ({
      ...prevCoach,
      [name]: value,
    }));
  };

  const handleLanguageChange = (e) => {
    const { name, value } = e.target;
    setLanguages((prevEducations) => ({
      ...prevEducations,
      [name]: value,
    }));
  };

  // Add a new language field
  const addNewLanguageField = () => {
    setLanguages([...languages, { language: "" }]); // Add a new empty language field
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  // Unified change handler for dynamic sections
  const handleSectionChange = (e, setFunction) => {
    const { name, value } = e.target;
    setFunction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Add new service to services list
  const addNewService = () => {
    setServices((prevServices) => [...prevServices, service]);
    setService({ name: "", price: "", duration: "", details: "" });
    setShowModal(false); // Close modal after adding service
  };

  // Handle modal open/close
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleModaleToggle = () => {
    setShoweModal(!showeModal);
  };

  // Add new education to educations list
  const addNewEducation = () => {
    setEducations((prevEducations) => [...prevEducations, education]);
    setEducation({ abbreviation: "", course: "", universityName: "" });
    setShoweModal(false);
  };

  // Add new language to languages list
  const addNewLanguage = () => {
    setLanguages((prevLang) => [...prevLang, language]);
    setLanguage("");
  };

  // Add new policy to policies list
  const addNewPolicies = () => {
    setPolicies((prevPol) => [...prevPol, policie]);
    setPolicie("");
  };

  // File upload function
  const upload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "scholist");

    try {
      const res = await axios.post(
        "https://api.cloudinary.com/v1_1/db82bjix0/image/upload",
        data
      );

      const { url } = res.data;
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = await upload(file);
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: "http://localhost:3000/v1/",
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      await client.post("/coach", {
        ...coach,
        img: url,
        services: services,
        education: educations,
        languages: languages,
        policies: policies,
      });

      navigate("/welcome");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="coach-application-form">
        <div class="container">
          <img
            className="headerlogo-icon7"
            alt=""
            src="/Logo Bird Vector.svg"
            //onClick={onButtonHomeClick}
          />
          <h1 class="headerText">Scholist Coach Application</h1>
        </div>
        <div className="form-body">
          <div className="sub-header">
            <p>Please submit the folllowing information</p>
          </div>
          <div className="top-section">
            <div className="image-upload-container">
              <label htmlFor="file-upload" className="custom-file-upload">
                <FaCloudUploadAlt className="cloud-icon" />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                className="file-input"
              />
              <p>Upload Photo</p>
            </div>
            <div className="top-section-right">
              <div>
                <label>
                  bio:
                  <input
                    type="text"
                    name="bio"
                    value={coach.bio}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div>
                <label>
                  expertise:
                  <input
                    type="text"
                    name="expertise"
                    value={coach.expertise}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div>
                <label>
                  location:
                  <input
                    type="text"
                    name="location"
                    value={coach.location}
                    onChange={handleChange}
                  />
                </label>
              </div>

              {/* <div>
                <label>
                  hourlyRate:
                  <input
                    type="text"
                    name="hourlyRate"
                    value={coach.hourlyRate}
                    onChange={handleChange}
                  />
                </label>
              </div> */}
            </div>
          </div>

          {/* Repeat the above pattern for other fields */}
          <div>
            {services.map((service, index) => (
              <div key={index} className="">
                <p>name: {service.name}</p>
                <p>details: {service.details}</p>
                <p>price: {service.price}</p>
                <p>duration: {service.duration}</p>
                <p>link: {service.bookingLink}</p>
              </div>
            ))}
          </div>

          <div className="sevice-bar">
            <label>
              <p> Services:</p>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Service Name"
                  value={service.name}
                  onChange={(e) => handleSectionChange(e, setService)}
                />
                <input
                  type="text"
                  name="details"
                  placeholder="Service Details"
                  value={service.details}
                  onChange={(e) => handleSectionChange(e, setService)}
                />
                <input
                  type="number"
                  name="price"
                  placeholder="Service Price"
                  value={service.price}
                  onChange={(e) => handleSectionChange(e, setService)}
                />
                <input
                  type="number"
                  name="duration"
                  placeholder="Service Duration"
                  value={service.duration}
                  onChange={(e) => handleSectionChange(e, setService)}
                />
                <input
                  type="text"
                  name="bookingLink"
                  placeholder="Booking Link"
                  value={service.bookingLink}
                  onChange={(e) => handleSectionChange(e, setService)}
                />
              </div>
              <button
                className="add-form"
                type="button"
                onClick={handleModalToggle}
              >
                Add a Service
              </button>
            </label>
          </div>

          <div className="second-section">
            <div>
              {educations.map((education, index) => (
                <div key={index}>
                  <p>abbreviation: {education.abbreviation}</p>
                  <p>course: {education.course}</p>
                  <p>universityName: {education.universityName}</p>
                </div>
              ))}
            </div>

            <div className="education-bar">
              <label>
                <p> Educations:</p>
                <div>
                  <input
                    type="text"
                    name="abbreviation"
                    placeholder="abbreviation"
                    value={education.abbreviation}
                    onChange={(e) => handleSectionChange(e, setEducation)}
                  />
                  <input
                    type="text"
                    name="course"
                    placeholder="course"
                    value={education.course}
                    onChange={(e) => handleSectionChange(e, setEducation)}
                  />
                  <input
                    type="text"
                    name="universityName"
                    placeholder="university name"
                    value={education.universityName}
                    onChange={(e) => handleSectionChange(e, setEducation)}
                  />
                </div>
                <button
                  className="add-form"
                  type="button"
                  onClick={handleModaleToggle}
                >
                  Add Education
                </button>
              </label>
            </div>
          </div>
          <div>
            {Array.isArray(languages) &&
              languages.map((language, index) => (
                <div key={index}>
                  <p>language: {language}</p>
                </div>
              ))}
          </div>

          <div className="sevice-bar">
            <label>
              <p>Languages:</p>
              <div>
                <input
                  type="text"
                  name="language"
                  placeholder="language"
                  value={language}
                  onChange={(e) => handleSectionChange(e, setLanguage)}
                />
              </div>
              <div className="add-form" onClick={addNewLanguage}>
                Add Language
              </div>
            </label>
          </div>

          <div>
            {Array.isArray(policies) &&
              policies.map((policie, index) => (
                <div key={index}>
                  <p>policie: {policie}</p>
                </div>
              ))}
          </div>

          <div className="sevice-bar">
            <label>
              <p> Policies:</p>
              <div>
                <input
                  type="text"
                  name="policie"
                  placeholder="policies"
                  value={policie}
                  onChange={(e) => handleSectionChange(e, setPolicie)}
                />
              </div>
              <div className="add-form" onClick={addNewPolicies}>
                Add Policy
              </div>
            </label>
          </div>

          <button className="submit-botton" type="submit">
            Submit
          </button>
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              {/* Cancel button at the top */}
              <button className="cancel-button" onClick={handleModalToggle}>
                +
              </button>

              <h2>Add a Service</h2>

              <label>Service Name:</label>
              <input
                type="text"
                name="name"
                value={service.name}
                onChange={handleServiceChange}
              />

              {/* Price and Duration on the same line */}
              <div className="flex-row">
                <div>
                  <label>Price:</label>
                  <input
                    type="text"
                    name="price"
                    value={service.price}
                    onChange={handleServiceChange}
                  />
                </div>

                <div>
                  <label>Duration:</label>
                  <select
                    name="duration"
                    value={service.duration}
                    onChange={handleServiceChange}
                  >
                    <option value="">Select Duration</option>
                    <option value="30 mins">30 mins</option>
                    <option value="1 hour">1 hour</option>
                    <option value="2 hours">2 hours</option>
                  </select>
                </div>
              </div>

              <label>Description:</label>
              <textarea
                name="details"
                value={service.details}
                onChange={handleServiceChange}
              />

              {/* Save button (full width) */}
              <button className="save-button" onClick={addNewService}>
                Save
              </button>
            </div>
          </div>
        )}
        {showeModal && (
          <div className="modal">
            <div className="modal-content">
              {/* Cancel button at the top */}
              <button className="cancel-button" onClick={handleModaleToggle}>
                +
              </button>

              <h2>Add Education</h2>

              <label>Abbrev:</label>
              <input
                type="text"
                name="abbreviation"
                placeholder="abbreviation"
                value={education.abbreviation}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              <label>Course:</label>
              <input
                type="text"
                name="course"
                placeholder="course"
                value={education.course}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              <label>University Name:</label>
              <input
                type="text"
                name="universityName"
                placeholder="university name"
                value={education.universityName}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              {/* Save button (full width) */}
              <button className="save-button" onClick={addNewEducation}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileForm;
